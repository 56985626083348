/*
 * 作者：宫商角徵羽
 * 微信：NANSA_Bee
 * 创建日期：2024/12/10
 */

import React, { useState, useEffect } from 'react';
import './RankList.css';
interface TraderData {
  nickname: string;
  avatarUrl: string;
  balanceAmount: number;
  balanceAsset: string;
  totalPnl: number;
  totalPnlAsset: string;
  totalRoi: number;
  startTime: number;
}
const RankList: React.FC = () => {
  const [data, setData] = useState<TraderData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const calculateDaysElapsed = (startTime: number) => {
    const now = new Date().getTime();
    const diffInMs = now - startTime;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/get-copy-trader`);
        const result = await response.json();
        if (result.success) {
          setData(result.data);
        } else {
          alert('Failed to fetch data');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) {
    return <div>准备中🚀...</div>;
  }
  return (
    <div className="stat-items">
      <div className="ranklist-container">
        {data.length > 0 && (
          <div className="first-place-card">
            <img src={data[0].avatarUrl} alt={data[0].nickname} className="first-avatar" />
            <div className="trophy-badge">⭐⭐⭐🎖️🎖️🎖️万户侯🎖️🎖️🎖️⭐⭐⭐</div>
            <h2 className="first-nickname">{data[0].nickname}</h2>
            <p className="first-stat">跟单资金: ${data[0].balanceAmount.toFixed(2)}</p>
            <p className="first-stat">
              总盈亏:
              <span
                style={{
                  color: data[0].totalPnl >= 0 ? '#2ebd85' : 'red',
                }}
              >
                {data[0].totalPnl >= 0 ? `+$${data[0].totalPnl.toFixed(2)}` : `-$${Math.abs(data[0].totalPnl).toFixed(2)}`}
              </span>
            </p>
            <p className="first-stat">累计收益率: {data[0].totalRoi.toFixed(2)}%</p>
            <p className="first-stat">累计跟单天数: {calculateDaysElapsed(data[0].startTime)}天</p>
          </div>
        )}
        <div className="ranklist">
          {data.slice(1).map((trader, index) => {
            const isSecond = index === 0;
            const isThird = index === 1;
            return (
              <div className={`card ${isSecond ? 'second' : isThird ? 'third' : ''}`} key={index + 1}>
                <img src={trader.avatarUrl} alt={trader.nickname} className="avatar" />
                <div className="info">
                  {(isSecond) && (
                    <div className="trophy-badge2">⭐🎖️🎖️小财主🎖️🎖️⭐</div>
                  )}
                  {(isThird) && (
                    <div className="trophy-badge3">⭐🎖️富甲一方🎖️⭐</div>
                  )}
                  <h3 className="nickname">{trader.nickname}</h3>
                  <p className="first-stat">跟单资金: ${trader.balanceAmount.toFixed(2)}</p>
                  <p className="first-stat">
                    总盈亏:
                    <span
                      style={{
                        color: trader.totalPnl >= 0 ? '#2ebd85' : 'red',
                      }}
                    >
                      {trader.totalPnl >= 0 ? `+$${trader.totalPnl.toFixed(2)}` : `-$${Math.abs(trader.totalPnl).toFixed(2)}`}
                    </span>
                  </p>
                  <p className="first-stat">累计收益率: {trader.totalRoi.toFixed(2)}%</p>
                  <p className="first-stat">累计跟单天数: {calculateDaysElapsed(trader.startTime)}天</p>

                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default RankList;
