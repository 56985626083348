import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import './Dashboard.css';
import BinanceCopyTrading from '../utils/Binance/BinanceCopyTrading';
import Positions from '../utils/Positions/Positions';
import TradingView from '../utils/TradingView/TradingView';
import Buys from '../utils/Buys/Buys';
import Sales from '../utils/Sales/Sales';
import RankList from '../utils/RankList/RankList';
import Milestones from '../utils/Milestones/Milestones';
import Statistics from '../utils/Statistics/Statistics';
import TradingHistory from '../utils/TradingHistory/TradingHistory';
import leopard from '../Pic/leopard.png';
import binance from '../Pic/binance.svg';
import excel from '../Pic/excel.svg';
import king from '../Pic/king.svg';
import scalen from '../Pic/scalen.svg';
import buy from '../Pic/buy.svg';
import sale from '../Pic/sale.svg';
import math from '../Pic/math.svg';
import milestone from '../Pic/milestone.svg';
import tradingview from '../Pic/tradingview.svg';
import Footer from './Footer'; 

const Dashboard: React.FC = () => {
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(true);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
    const handleMouseEnter = () => {
      setSidebarOpen(true);
    };
    const handleMouseLeave = () => {
      setSidebarOpen(false);
    };
  return (
    <Router>
      <div className="dashboard-container">
        <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}
         onMouseEnter={handleMouseEnter}  
         onMouseLeave={handleMouseLeave} 
        >
          <div className="sidebar-header">
          <img 
              src={isSidebarOpen ? leopard : leopard} 
              alt="Logo" 
              className="logo" 
              style={{
                width: isSidebarOpen ? '150px' : '30px', 
                height: 'auto',  
              }}
            />
          </div>
          <Link to="/binance-copy-trading" className="sidebar-item">
            <img src={binance} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text" style={{ fontSize: '22px' }}>概览</span>
          </Link>
          <Link to="/positions" className="sidebar-item">
          <img src={scalen} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text"style={{ fontSize: '22px' }}>持仓</span>
          </Link>
          <Link to="/buys" className="sidebar-item">
          <img src={buy} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text"style={{ fontSize: '22px' }}>下单订单薄</span>
          </Link>
          <Link to="/sales" className="sidebar-item">
          <img src={sale} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text"style={{ fontSize: '22px' }}>平仓订单薄</span>
          </Link>
          <Link to="/trade-statistics" className="sidebar-item">
          <img src={math} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text"style={{ fontSize: '22px' }}>交易统计</span>
          </Link>
          <Link to="/rank-list" className="sidebar-item">
          <img src={king} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text"style={{ fontSize: '22px' }}>跟单龙虎榜</span>
          </Link>
          <Link to="/milestones" className="sidebar-item">
          <img src={milestone} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text"style={{ fontSize: '22px' }}>资金里程碑</span>
          </Link>
          <Link to="/tradingview" className="sidebar-item">
          <img src={tradingview} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text"style={{ fontSize: '22px' }}>TradingView</span>
          </Link>
          <Link to="/trading-history" className="sidebar-item">
          <img src={excel} alt="Icon" style={{ width: '30px', height: 'auto', marginRight: '8px' }} />
            <span className="item-text"style={{ fontSize: '22px' }}>施工别点</span>
          </Link>
          <button className="sidebar-toggle" onClick={toggleSidebar}>
            {isSidebarOpen ? '.<' : '>.'}
          </button>
        </div>
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Navigate to="/binance-copy-trading" replace />} />
            <Route path="/binance-copy-trading" element={<BinanceCopyTrading />} />
            <Route path="/positions" element={<Positions />} />
            <Route path="/tradingview" element={<TradingView />} />
            <Route path="/buys" element={<Buys />} />
            <Route path="/sales" element={<Sales />} />
            <Route path="/rank-list" element={<RankList />} />
            <Route path="/milestones" element={<Milestones />} />
            <Route path="/trade-statistics" element={<Statistics />} />
            <Route path="/trading-history" element={<TradingHistory />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};
export default Dashboard;