/*
 * 作者：宫商角徵羽
 * 微信：NANSA_Bee
 * 创建日期：2024/12/10
 */

import React, { useState, useEffect, useCallback } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import './Milestones.css';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
interface DailyTCVStats {
  date: string;
  dateEpoch: number;
  minTCV: number;
  TCV: number;
  order: number;
}
interface DailyPNLStats {
  date: string;
  cumulativeProfitCurrency: number;
}
interface TransferHistory {
  asset: string;
  time: number;
  amount: string;
  from: string;
  to: string;
  transType: string;
}
const Milestones = () => {
  const [chartData, setChartData] = useState<any>(null);
  const [chartData2, setChartData2] = useState<any>(null);
  const [data, setData] = useState<TransferHistory[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const fetchData = useCallback(() => {
    if (loading || !hasMore) return;
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/binance/transfer-history?pageNumber=${pageNumber}&pageSize=10&portfolioId=4145031067802711040`)
      .then(response => response.json())
      .then(result => {
        if (result.success) {
          setData(prevData => [...prevData, ...result.data]);
          setHasMore(result.data.length > 0);
        }
      })
      .catch(error => console.error('Error fetching data:', error))
      .finally(() => {
        setLoading(false);
        setPageNumber(prevPage => prevPage + 1);
      });
  }, [pageNumber, loading, hasMore]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const translateAccountType = (account: string) => {
    if (account === 'Lead Trading Account') {
      return '带单资金账户';
    } else if (account === 'Spot') {
      return '现货资金账户';
    }
    return account;
  };
  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const bottom = e.currentTarget.scrollHeight === e.currentTarget.scrollTop + e.currentTarget.clientHeight;
    if (bottom) {
      fetchData();
    }
  };
  const formatTime = (timestamp: number) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/trading-dataset`);
        const data = await response.json();

        if (data.success && data.stats.extra && data.stats.extra.dailyTCVStats && data.stats.extra.dailyPNLStats) {
          const dailyTCVStats: DailyTCVStats[] = data.stats.extra.dailyTCVStats;
          const dailyPNLStats: DailyPNLStats[] = data.stats.extra.dailyPNLStats;
          const dates = dailyTCVStats.map(item => item.date);
          const tcvs = dailyTCVStats.map(item => item.TCV);
          const totalProfitCurrency = dailyPNLStats.map(item => item.cumulativeProfitCurrency);
          const dates2 = dailyPNLStats.map(item => item.date);
          setChartData({
            labels: dates,
            datasets: [
              {
                label: 'TCV',
                data: tcvs,
                fill: false,
                borderColor: '#d0ff14',
                tension: 0.5,
              },
            ],
          });
          setChartData2({
            labels: dates2,
            datasets: [
              {
                label: 'CPC',
                data: totalProfitCurrency,
                fill: false,
                borderColor: '#00d6e2',
                tension: 0.5,
              },
            ],
          });
        } else {
          console.error('No order data found');
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        }
      },
      y: {
        title: {
          display: true,
          text: '(USD)'
        },
        beginAtZero: true
      }
    }
  };
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
        }
      },
      y: {
        title: {
          display: true,
          text: '(USD)'
        },
        beginAtZero: true
      }
    }
  };
  return (
    <div>
      <div>
        <div className="milestones-container2">
          <h2>带单账户资金</h2>
          {chartData ? (
            <Line data={chartData} options={options} width={3000} height={550} />
          ) : (
            <div>准备中🚀...</div>
          )}
        </div>
        <div className="milestones-container2">
          <h2>利润积累</h2>
          {chartData2 ? (
            <Line data={chartData2} options={options2} width={3000} height={550} />
          ) : (
            <div>准备中🚀...</div>
          )}
        </div>
        <div className="milestones-container" onScroll={handleScroll}>
          {data.length > 0 ? (
            <ul>
              {data.map((item, index) => (
                <li key={index} className="milestone-item">
                  <div className="description">
                    <span style={{ color: '#e5a9ff', fontWeight: 'bold' }}>{formatTime(item.time)} </span> 带单员从他的【<span style={{ fontWeight: 'bold' }}>{translateAccountType(item.from)}</span>】转划到【<span style={{ fontWeight: 'bold' }}>{translateAccountType(item.to)}</span>】共计： <span style={{ color: '#2ebd85', fontWeight: 'bold' }}>{Number(item.amount).toFixed(2)}</span> <span style={{ color: '#FFA500', fontWeight: 'bold' }}>{item.asset}</span>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div>无更多可用历史记录。</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Milestones;

