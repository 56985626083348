/*
 * 作者：宫商角徵羽
 * 微信：NANSA_Bee
 * 创建日期：2024/12/10
 */

import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const TradingHistory = () => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>不让你点，不让你点，你偏要点!</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#343a40',
  },
  text: {
    fontSize: 30,
    color: '#fff6f3',
  },
});

export default TradingHistory;
