/*
 * 作者：宫商角徵羽
 * 微信：NANSA_Bee
 * 创建日期：2024/12/10
 */

import React, { useEffect, useState } from 'react';
import './Positions.css';
import AWP from '../../Pic/awp.svg';
import Bug from '../../Pic/bug.svg';
type ListItemProps = {
    firstBoughtDate: number;
    currency: string;
    market: string;
    profit: string;
    currentPrice: string;
    currentValue: string;
    totalAmount: string;
    lastBoughtDate: number;
    volume: string;
    avgPrice: string;
    totalCost: string;
    type: string;
    percChange: string;
};
const formatDate = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString();
};
const formatRelativeTime = (timestamp: number) => {
    const now = Date.now();
    const diffInSeconds = Math.floor((now - timestamp * 1000) / 1000);
    if (diffInSeconds < 3600) {
        return "1小时内";
    } else if (diffInSeconds < 6 * 3600) {
        return "6小时内";
    } else if (diffInSeconds < 12 * 3600) {
        return "12小时内";
    } else if (diffInSeconds < 24 * 3600) {
        return "一天内";
    } else if (diffInSeconds < 2 * 24 * 3600) {
        return "两天内";
    } else if (diffInSeconds < 3 * 24 * 3600) {
        return "三天内";
    } else if (diffInSeconds < 7 * 24 * 3600) {
        return "一周内";
    } else if (diffInSeconds < 30 * 24 * 3600) {
        return "一个月内";
    } else {
        return "超过一个月";
    }
};
const ListItem = ({
    firstBoughtDate,
    currency,
    profit,
    currentPrice,
    currentValue,
    totalAmount,
    lastBoughtDate,
    volume,
    avgPrice,
    totalCost,
    percChange,
    type
}: ListItemProps) => {
    const [assetLogos, setAssetLogos] = useState<{ [key: string]: string }>({});
    useEffect(() => {
        const fetchAssetLogo = async (currency: string) => {
            try {
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/asset-logos?asset=${currency}`;
                const response = await fetch(apiUrl);
                const data = await response.json();
                if (data.success && data.pic) {
                    setAssetLogos((prevLogos) => ({
                        ...prevLogos,
                        [currency]: data.pic,
                    }));
                }
            } catch (error) {
                console.error('Error fetching asset logo:', error);
            }
        };
        fetchAssetLogo(currency);
    }, [currency]);
    return (
        <div className="item-row">
            <div className="item-cell"> {formatDate(firstBoughtDate)}<br />({formatRelativeTime(lastBoughtDate)})</div>
            <div className="item-cell">
                {type === 'PAIRS' ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={AWP} alt="PAIRS Icon" style={{ width: 'auto', height: '46px' }} />
                        <span style={{ fontSize: '10px', color: '#f800ff', textAlign: 'right', transform: 'rotate(-10deg)' }}>Sniper</span>
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={Bug} alt="DCA Icon" style={{ width: 'auto', height: '50px' }} />
                        <span style={{ fontSize: '10px', color: '#ffa900', textAlign: 'right', transform: 'rotate(-10deg)' }}>MEV</span>
                    </div>
                )}
            </div>
            <div className="item-cell" style={{ display: 'flex', alignItems: 'center' }}>
                {assetLogos[currency] && (
                    <img
                        src={assetLogos[currency]}
                        alt={`${currency} logo`}
                        style={{ width: '40px', height: '40px', marginRight: '10px' }}
                    />
                )}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{currency}</span>
                    <span style={{ fontSize: '14px', color: '#2ebd85', fontWeight: 'bold' }}>{`$${volume}`}</span>
                </div>
            </div>
            <div className="item-cell"
                style={{
                    color: parseFloat(profit) < 0 ? 'orange' : '#2ebd85',
                    fontWeight: 'bold'
                }}>
                {profit}%
            </div>
            <div className="item-cell">
                ${currentPrice ? parseFloat(currentPrice).toFixed(5) : '0.00000'}<br />
                ${avgPrice ? parseFloat(avgPrice).toFixed(5) : '0.00000'}
            </div>
            <div className="item-cell">
                ${currentValue ? parseFloat(currentValue).toFixed(2) : '0.00'}<br />
                ${totalCost ? parseFloat(totalCost).toFixed(2) : '0.00'}<br />
                <span
                    style={{
                        color: (parseFloat(currentValue) - parseFloat(totalCost)) >= 0 ? '#2ebd85' : 'red',
                        fontWeight: 'bold'
                    }}
                >
                    {
                        (parseFloat(currentValue) - parseFloat(totalCost)) >= 0
                            ? `+$${(parseFloat(currentValue) - parseFloat(totalCost)).toFixed(2)}`
                            : `-$${(Math.abs(parseFloat(currentValue) - parseFloat(totalCost))).toFixed(2)}`
                    }
                </span>
            </div>
            <div className="item-cell" style={{ fontWeight: 'bold' }}>{totalAmount}</div>
            <div className="item-cell" style={{
                fontSize: '16px', color: parseFloat(percChange) < 0 ? 'red' : '#2ebd85',
                fontWeight: 'bold'
            }}>{percChange}</div>
        </div>
    );
};
const Positions = () => {
    const [positions, setPositions] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [totalValue1, setTotalValue1] = useState<number | null>(null);
    const [totalCost1, setTotalCost1] = useState<number | null>(null);
    const [totalCost3, setTotalCost3] = useState<number | null>(null);
    const [totalValue3, setTotalValue3] = useState<number | null>(null);
    const fetchTradingDataset = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/trading-dataset`);
            const data = await response.json();
            if (data.success && data.positionCount) {
                const position1 = data.positionCount[1];
                const position3 = data.positionCount[3];
                if (position1 && position1.totals) {
                    setTotalValue1(position1.totals.totalValue);
                    setTotalCost1(position1.totals.totalCost);
                }
                if (position3 && position3.totals) {
                    setTotalCost3(position3.totals.totalCost);
                    setTotalValue3(position3.totals.totalValue);
                }
            }
        } catch (error) {
            console.error('Error fetching trading dataset:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchTradingDataset();
        const intervalId = setInterval(() => {
          fetchTradingDataset();
        }, 60000);
        return () => clearInterval(intervalId);
      }, []);
      useEffect(() => {
        const fetchTradingDataset = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/trading-dataset`);
            const data = await response.json();
    
            const combinedData = [
              ...data.stats.currentPositions.PAIRS.map((item: any) => ({ ...item, type: 'PAIRS' })),
              ...data.stats.currentPositions.DCA.map((item: any) => ({ ...item, type: 'DCA' }))
            ];
    
            const sortedData = combinedData.sort((a, b) => parseFloat(b.profit) - parseFloat(a.profit));
            setPositions(sortedData);
          } catch (error) {
            console.error('Error fetching data:', error);
          } finally {
            setLoading(false);
          }
        };
        fetchTradingDataset();
        const interval = setInterval(fetchTradingDataset, 1000);
        return () => clearInterval(interval);
      }, []);
    if (loading) {
        return <div>准备中🚀...</div>;
    }
    
    return (
        <div className="stat-items">
            <div className="positions-container">
                <div className="header-row">
                    <div className="header-cell">建仓日期<br />保持交易活跃</div>
                    <div className="header-cell">算法订单</div>
                    <div className="header-cell">品种<br />24小时成交额</div>
                    <div className="header-cell">利润</div>
                    <div className="header-cell">市价<br />持仓均价</div>
                    <div className="header-cell">持仓市值<br />成交市值<br />获利</div>
                    <div className="header-cell">持仓量</div>
                    <div className="header-cell">振幅</div>
                </div>
                <div className="data-scrollable">
                    {positions.slice(0, 20).map((item) => (
                        <ListItem
                            key={item.currency + item.market}
                            firstBoughtDate={item.firstBoughtDate}
                            currency={item.currency}
                            market={item.market}
                            profit={item.profit}
                            currentPrice={item.currentPrice}
                            currentValue={item.currentValue}
                            totalAmount={item.totalAmount}
                            lastBoughtDate={item.lastBoughtDate}
                            volume={item.volume}
                            avgPrice={item.avgPrice}
                            totalCost={item.totalCost}
                            percChange={item.percChange}
                            type={item.type}
                        />
                    ))}
                </div>
                <div className="left-align">
                    <strong>持仓市值：</strong>
                    {totalValue1 !== null && totalValue3 !== null
                        ? `$${(totalValue1 + totalValue3).toFixed(2)}`
                        : ''}
                </div>
                <div className="left-align">
                    <strong>差异：</strong>
                    {totalValue1 !== null && totalValue3 !== null && totalCost1 !== null && totalCost3 !== null
                        ? (
                            <span
                                style={{
                                    color: (((totalValue1 + totalValue3) - (totalCost1 + totalCost3)) / (totalCost1 + totalCost3) * 100) < 0 ? 'red' : '2ebd85',
                                    fontWeight: 'bold'
                                }}
                            >
                                ({(((totalValue1 + totalValue3) - (totalCost1 + totalCost3)) / (totalCost1 + totalCost3) * 100).toFixed(2)}%)
                            </span>
                        )
                        : ''}
                </div>
                <div className="left-align">
                    <strong>持仓花费：</strong>
                    {totalCost1 !== null && totalCost3 !== null
                        ? `$${(totalCost1 + totalCost3).toFixed(2)}`
                        : ''}
                </div>
                <div className="left-align">
                    <strong>持仓费用：</strong>
                    {totalCost1 !== null && totalCost3 !== null
                        ? `$${(totalCost1 + totalCost3).toFixed(6)}`
                        : ''}
                </div>
            </div>
        </div>
    );
};

export default Positions;
