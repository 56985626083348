/*
 * 作者：宫商角徵羽
 * 微信：NANSA_Bee
 * 创建日期：2024/12/10
 */

import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import './Statistics.css';
interface DailyStats {
  date: string;
  totalSales: number;
  totalBuys: number;
  avgGrowth: number;
  avgProfit: number;
  totalBoughtCost: number;
  totalSoldValue: number;
}
interface MonthlyStats {
  month: string;
  totalSales: number;
  totalBuys: number;
  totalBoughtCost: number;
  totalSoldValue: number;
}
interface ProfitablePairs {
  coin: string;
  avg: number;
  profitCurrency: number;
  soldTimes: number;
}
const Milestones = () => {
  const [chartData1, setChartData1] = useState<any>(null);
  const [chartData2, setChartData2] = useState<any>(null);
  const [chartData3, setChartData3] = useState<any>(null); 
  const [chartData4, setChartData4] = useState<any>(null);
  const [chartData5, setChartData5] = useState<any>(null); 
  const [chartData6, setChartData6] = useState<any>(null);
  const [profitablePairs, setProfitablePairs] = useState<ProfitablePairs[]>([]);
  const [assetLogos, setAssetLogos] = useState<{ [key: string]: string }>({}); 
  const [loading, setLoading] = useState<boolean>(true);
  const fetchAssetLogo = async (baseAsset: string) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/asset-logos?asset=${baseAsset}`;
      const response = await fetch(apiUrl);
      const data = await response.json();
      if (data.success && data.pic) {
        setAssetLogos((prevLogos) => ({
          ...prevLogos,
          [baseAsset]: data.pic, 
        }));
      }
    } catch (error) {
      console.error('Error fetching asset logo:', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/trading-dataset`);
        const data = await response.json();
        if (data.success && data.stats.extra) {
          const dailyStats: DailyStats[] = data.stats.extra.dailyStats;
          const monthlyStats: MonthlyStats[] = data.stats.extra.monthlyStats;
          const profitablePairsObj: Record<string, ProfitablePairs> = data.stats.extra.profitablePairs;
          const profitablePairsArray = Object.values(profitablePairsObj);
          setProfitablePairs(profitablePairsArray);
          profitablePairsArray.forEach((pair) => {const baseAsset = pair.coin.replace(/USDT$/, ''); fetchAssetLogo(baseAsset);});
          const reversedDailyStats = dailyStats.reverse();
          const reversedMonthlyStats = monthlyStats.reverse();
          const dates = reversedDailyStats.map(item => item.date);
          const totalSales = reversedDailyStats.map(item => item.totalSales);
          const totalBuys = reversedDailyStats.map(item => item.totalBuys);
          const totalBoughtCost = reversedDailyStats.map(item => item.totalBoughtCost);
          const totalSoldValue = reversedDailyStats.map(item => item.totalSoldValue);
          const avgGrowth = reversedDailyStats.map(item => item.avgGrowth);
          const avgProfit = reversedDailyStats.map(item => item.avgProfit);
          const months = reversedMonthlyStats.map(item => item.month);
          const totalSalesMonthly = reversedMonthlyStats.map(item => item.totalSales);
          const totalBuysMonthly = reversedMonthlyStats.map(item => item.totalBuys);
          const totalBoughtCostMonthly = reversedMonthlyStats.map(item => item.totalBoughtCost);
          const totalSoldValueMonthly = reversedMonthlyStats.map(item => item.totalSoldValue);
          setChartData1({
            labels: dates,
            datasets: [
              {
                label: '买单',
                data: totalBuys,
                fill: false,
                borderColor: '#30ff21',
                tension: 0.5,
              },
              {
                label: '卖单',
                data: totalSales,
                fill: false,
                borderColor: '#ff033e',
                tension: 0.5,
              },

            ],
          });
          setChartData2({
            labels: dates,
            datasets: [
              {
                label: '购买量',
                data: totalBoughtCost,
                fill: false,
                borderColor: '#eecd00',
                tension: 0.5,
              },
              {
                label: '卖出量',
                data: totalSoldValue,
                fill: false,
                borderColor: '#dd14ab',
                tension: 0.5,
              },
            ],
          });
          setChartData5({
            labels: dates,
            datasets: [
              {
                label: '增长率',
                data: avgGrowth,
                fill: false,
                borderColor: '#73fdff',
                tension: 0.1,
              },
            ],
          });
          setChartData3({
            labels: months,
            datasets: [
              {
                label: '买单',
                data: totalBuysMonthly,
                fill: false,
                borderColor: '#30ff21',
                tension: 0.5,
              },
              {
                label: '卖单',
                data: totalSalesMonthly,
                fill: false,
                borderColor: '#ff033e',
                tension: 0.5,
              },

            ],
          });
          setChartData4({
            labels: months,
            datasets: [
              {
                label: '购买量',
                data: totalBoughtCostMonthly,
                fill: false,
                borderColor: '#eecd00',
                tension: 0.5,
              },
              {
                label: '卖出量',
                data: totalSoldValueMonthly,
                fill: false,
                borderColor: '#dd14ab',
                tension: 0.5,
              },
            ],
          });
          setChartData6({
            labels: dates,
            datasets: [
              {
                label: '平均增长率',
                data: avgProfit,
                fill: false,
                borderColor: '#ff7013',
                tension: 0.5,
              },
            ],
          });

          setLoading(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <div>准备中🚀...</div>;
  }
  return (
    <div className="charts-container">
      <div className="chart-row">
        <div className="chart-item">
          <h3>日买单 vs 卖单</h3>
          <Line data={chartData1} />
        </div>
        <div className="chart-item">
          <h3>日交易量(USDT)</h3>
          <Line data={chartData2} />
        </div>
        <div className="chart-item">
          <h3>日增长率%</h3>
          <Line data={chartData5} />
        </div>
      </div>
      <div className="chart-row">

        <div className="chart-item">
          <h3>月买单 vs 卖单</h3>
          <Line data={chartData3} />
        </div>
        <div className="chart-item">
          <h3>月交易量(USDT)</h3>
          <Line data={chartData4} />
        </div>

        <div className="chart-item">
          <h3>加权增长率%</h3>
          <Line data={chartData6} />
        </div>
      </div>
      <h3>最赚钱的交易对</h3>
      <div className="scrollable-table-container">
        <table>
          <thead>
            <tr>
              <th>交易对</th>
              <th>卖出次数</th>
              <th>平均利润</th>
              <th>总利润</th>
            </tr>
          </thead>
          <tbody>
            {profitablePairs.map((pair) => (
              <tr key={pair.coin}>
                <td>
                  {assetLogos[pair.coin.replace(/USDT$/, '')] ? (
                    <img
                      src={assetLogos[pair.coin.replace(/USDT$/, '')]}
                      alt={pair.coin}
                      style={{ width: '20px', marginRight: '8px' }}
                    />
                  ) : (
                    ''
                  )}
                  {pair.coin}
                </td>
                <td>{pair.soldTimes}</td>
                <td style={{ color: '#2ebd85' }}>+${pair.avg ? pair.avg.toFixed(2) : ''}</td>
                <td style={{ color: '#2ebd85' }}>+${pair.profitCurrency ? pair.profitCurrency.toFixed(2) : ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Milestones;
