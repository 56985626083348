
/*
 * 作者：宫商角徵羽
 * 微信：NANSA_Bee
 * 创建日期：2024/12/10
 */

import React, { useEffect, useState } from 'react';
const TradingViewWidget = () => {
  const [embedCode, setEmbedCode] = useState<string>('');
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/tradingview`)
      .then(response => response.text())
      .then(data => {
        setEmbedCode(data);
      })
      .catch(error => console.error('Error fetching TradingView embed code:', error));
  }, []);
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: embedCode }} />
    </div>
  );
};
export default TradingViewWidget;
