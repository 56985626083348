/*
 * 作者：宫商角徵羽
 * 微信：NANSA_Bee
 * 创建日期：2024/12/10
 */

import React, { useEffect, useState } from 'react';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import './BinanceCopyTrading.css';
import Shield from '../../Pic/shield.svg';
import ShieldOff from '../../Pic/shield-off.svg';
import ETH from '../../Pic/ethereum.svg';
import BTC from '../../Pic/btc_logo.svg';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.register(ArcElement, Tooltip, Legend);
interface BinanceData {
  nickname: string;
  avatarUrl: string;
  description: string;
  favoriteCount: number;
  currentCopyCount: number;
  maxCopyCount: number;
  totalCopyCount: number;
  walletBalanceAmount: string;
  walletBalanceAsset: string;
  currentInvestAmount: string;
  currentAvailableAmount: string;
  aumAmount: string;
  aumAsset: string;
  copierPnl: string;
  copierPnlAsset: string;
  profitSharingRate: string;
  sharpRatio: string;
  joinDays: number;
  fixedRadioMinCopyUsd: number;
  fixedAmountMinCopyUsd: number;
  lastTradeTime: number;
  notification: string
}
interface PerformanceData {
  aum: string;
  roi: string;
  pnl: string;
  mdd: string;
  winRate: string;
  winDays: number;
  sharpRatio: string;
}
interface ChartDataPoint {
  value: number;
  dataType: string;
  dateTime: number;
}
interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    fill: boolean;
    tension: number;
  }[];
}
interface AssetData {
  asset: string;
  volume: number;
}
interface AssetLogo {
  asset: string;
  pic: string;
}
const BinanceCopyTrading = () => {
  const [data, setData] = useState<BinanceData | null>(null);
  const [performance, setPerformance] = useState<PerformanceData | null>(null);
  const [timeRange, setTimeRange] = useState<string>('7D');
  const [chartData, setChartData] = useState<ChartData | null>(null);
  const [dataType, setDataType] = useState<string>('ROI');
  const [totalCombinedToday, setTotalCombinedToday] = useState<number | null>(null);
  const [totalCombinedYesterday, setTotalCombinedYesterday] = useState<number | null>(null);
  const [totalCombinedWeek, setTotalCombinedWeek] = useState<number | null>(null);
  const [totalProfitLastMonth, setTotalProfitLastMonth] = useState<number | null>(null);
  const [assetLogos, setAssetLogos] = useState<AssetLogo[]>([]);
  const [, setLoading] = useState<boolean>(false);
  const [serverTime, setServerTime] = useState<number | null>(null);
  const [delay, setDelay] = useState<number | null>(null);
  const [btcPrice, setBtcPrice] = useState<number | null>(null);
  const [prevBtcPrice, setPrevBtcPrice] = useState<number | null>(null);
  const [ethPrice, setEthPrice] = useState<number | null>(null);
  const [prevEthPrice, setPrevEthPrice] = useState<number | null>(null);
  const [, setError] = useState<string | null>(null);
  const [totalCombinedPercToday, setTotalCombinedPercToday] = useState<number | null>(null);
  const [totalCombinedPercYesterday, setTotalCombinedPercYesterday] = useState<number | null>(null);
  const [totalCombinedPercWeek, setTotalCombinedPercWeek] = useState<number | null>(null);
  const [totalProfitPercLastMonth, setTotalProfitPercLastMonth] = useState<number | null>(null);
  const [notification, setNotifications] = useState<string | null>(null);
  const [pieChartData, setPieChartData] = useState({
    labels: [] as string[],
    datasets: [
      {
        data: [] as number[],
        backgroundColor: [] as string[],
      },
    ],
  })
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/binance-market`);
      const data = await response.json();

      if (data.success) {
        const newBtcPrice = parseFloat(data.btcPrice);
        const newEthPrice = parseFloat(data.ethPrice);
        setServerTime(data.serverTime);
        setDelay(data.delay);
        setPrevBtcPrice(btcPrice);
        setPrevEthPrice(ethPrice);
        setBtcPrice(newBtcPrice);
        setEthPrice(newEthPrice);
      } else {
        setError('数据获取失败');
      }
    } catch (error) {
      setError('请求失败');
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 1000);
    return () => clearInterval(interval);
  }, [btcPrice, ethPrice]);
  const getBtcPriceStyle = () => {
    if (btcPrice === null || prevBtcPrice === null) return { color: 'white', fontSize: '16px', fontWeight: 'bold' };
    if (btcPrice > prevBtcPrice) return { color: '#2ebd85', fontSize: '16px', fontWeight: 'bold' };
    if (btcPrice < prevBtcPrice) return { color: 'red', fontSize: '16px', fontWeight: 'bold' };
    return { color: 'white', fontSize: '16px', fontWeight: 'bold' };
  };
  const getEthPriceStyle = () => {
    if (ethPrice === null || prevEthPrice === null) return { color: 'white', fontSize: '16px', fontWeight: 'bold' };
    if (ethPrice > prevEthPrice) return { color: '#2ebd85', fontSize: '16px', fontWeight: 'bold' };
    if (ethPrice < prevEthPrice) return { color: 'red', fontSize: '16px', fontWeight: 'bold' };
    return { color: 'white', fontSize: '16px', fontWeight: 'bold' };
  };
  useEffect(() => {
    const fetchAssetLogo = async (asset: string) => {
      try {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ASSET_LOGO_ENDPOINT}${asset}`;
        const response = await fetch(apiUrl);
        const data = await response.json();
        if (data.success) {
          setAssetLogos((prevLogos) => [
            ...prevLogos,
            { asset: data.asset, pic: data.pic },
          ]);
        }
      } catch (error) {
        console.error('Error fetching asset logo:', error);
      }
    };
    if (pieChartData?.labels) {
      pieChartData.labels.forEach((label) => {
        if (label !== 'OTHER') {
          fetchAssetLogo(label);
        }
      });
    }
  }, [pieChartData.labels]);
  const fetchPieChartData = async (timeRange: string) => {
    try {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${apiUrl}/coin-preference?timeRange=${timeRange}`);
      const result = await response.json();

      if (result.code === '000000' && result.data) {
        processPieChartData(result.data.data.data);
      }
    } catch (error) {
      console.error('Error fetching pie chart data:', error);
    }
  };
  const processPieChartData = (data: AssetData[]) => {
    const labels = data.map((item) => item.asset);
    const values = data.map((item) => item.volume);
    const formattedData = {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: [
            '#00BFFF',
            '#1E90FF',
            '#4682B4',
            '#5F9EA0',
            '#7B68EE',
            '#8A2BE2',
            '#9370DB',
            '#D8BFD8',
            '#FF69B4',
            '#FF1493',
            '#FF6347',
          ],
        },
      ],
    };
    setPieChartData(formattedData);
  };
  useEffect(() => {
    fetchPieChartData(timeRange);
  }, [timeRange]);
  const handleTimeRangeChange = (newTimeRange: string) => {
    setTimeRange(newTimeRange);
  };
  useEffect(() => {
    const fetchBinanceData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/binance`);
        const data = await response.json();
        if (data.success) {
          setData(data.data.data);
        }
      } catch (error) {
        console.error('Error fetching binance data:', error);
      }
    };
    fetchBinanceData();
  }, []);

  useEffect(() => {
    const fetchPerformanceData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/performance?portfolioId=4145031067802711040&timeRange=${timeRange}`
        );
        const data = await response.json();
        if (data.success) {
          setPerformance(data.data.data);
        }
      } catch (error) {
        console.error('Error fetching performance data:', error);
      }
    };
    fetchPerformanceData();
  }, [timeRange]);

  const fetchChartData = async (dataType: string, timeRange: string) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/performance-chart-data?dataType=${dataType}&portfolioId=4145031067802711040&timeRange=${timeRange}`
      );
      const result = await response.json();
      if (result.code === '000000' && result.data) {
        processChartData(result.data.data);
      }
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
  };
  const processChartData = (data: ChartDataPoint[]) => {
    const labels = data.map((item) => {
      const date = new Date(item.dateTime);
      return `${date.getMonth() + 1}/${date.getDate()}`;
    });
    const values = data.map((item) => item.value);
    const formattedData = {
      labels,
      datasets: [
        {
          label: dataType,
          data: values,
          borderColor: dataType === 'ROI' ? '#2ebd85' : 'rgb(47,239,16)',
          fill: false,
          tension: 0.3,
        },
      ],
    };
    setChartData(formattedData);
  };
  useEffect(() => {
    fetchChartData(dataType, timeRange);
  }, [dataType, timeRange]);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    }
  };
  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const dataset = tooltipItem.dataset;
            const currentValue = dataset.data[tooltipItem.dataIndex];
            const total = dataset.data.reduce((acc: number, value: number) => acc + value, 0);
            const percentage = ((currentValue / total) * 100).toFixed(2) + '%';
            return `${tooltipItem.label}: ${currentValue} (${percentage})`;
          },
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 1,
      },
    },
    cutout: '60%',
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/trading-dataset`);
        const data = await response.json();
        if (data.success) {
          setTotalCombinedToday(data.stats.basic.totalCombinedToday);
          setTotalCombinedYesterday(data.stats.basic.totalCombinedYesterday);
          setTotalCombinedWeek(data.stats.basic.totalCombinedWeek);
          setTotalProfitLastMonth(data.stats.basic.totalProfitLastMonth);
          setTotalCombinedPercToday(data.stats.basic.totalCombinedPercToday);
          setTotalCombinedPercYesterday(data.stats.basic.totalCombinedPercYesterday);
          setTotalCombinedPercWeek(data.stats.basic.totalCombinedPercWeek);
          setTotalProfitPercLastMonth(data.stats.basic.totalProfitPercLastMonth);
          setNotifications(data.notification[0].notification);
          let latestNotification = data.notification[0].notification;
          if (latestNotification === "Global SOM set to false") {
            latestNotification = (
              <span className="notification-icon">
                <img src={ShieldOff} alt="Shield Icon" width="18" height="18" />
                防御护盾已解除
              </span>
            );
          } else if (latestNotification === "Global SOM set to true") {
            latestNotification = (
              <span className="notification-icon">
                <img src={Shield} alt="Shield Icon" width="18" height="18" />
                防御护盾已启用
              </span>
            );
          } else if (latestNotification.startsWith("The bot will start trading at")) {
            latestNotification = latestNotification.replace(
              "The bot will start trading at",
              "🤖重新启动于"
            );
          }
          setNotifications(latestNotification);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  if (!data) {
    return <div>准备中🚀...</div>;
  }
  const copyStats = `${data.currentCopyCount}/${data.maxCopyCount}`;
  const formatTimestamp = (timestamp: number) => {
    const date = new Date(timestamp); 
    return date.toLocaleString(); 
  };
  return (
    <div className="binance-container">
      <div className="binance-header">
        <img src={data.avatarUrl} alt={data.nickname} className="avatar" />
        <div className="info">
          <h1 className="nickname">{data.nickname}</h1>
          <div className="description">{data.description}</div>
          {/*  API 交易标识 */}
          <button className="api-button" disabled>
            API交易
          </button>
          <div className="copy-count">
            <div>
              <span className="text">交易天数:</span> <span className="number">{data.joinDays}</span>
              <span className="divider"></span>
              <span className="text">跟单人数:</span> <span className="number">{copyStats}</span>
              <span className="divider"></span>
              <span className="text">总跟单人数:</span> <span className="number">{data.totalCopyCount}</span>
              <span style={getBtcPriceStyle()}>
                <img
                  src={BTC}
                  className="small-icon"
                  style={{ width: '19px', height: 'auto' }}
                  alt="BTC Icon"
                /> ${btcPrice ? btcPrice.toFixed(2) : '0.00'}
              </span>
              <span style={getEthPriceStyle()}>
                <img
                  src={ETH}
                  className="small-icon"
                  style={{ width: '20px', height: 'auto' }}
                  alt="ETH Icon"
                />${ethPrice ? ethPrice.toFixed(2) : '0.00'}
              </span>
              <span className="text">  市场时间：{serverTime && formatTimestamp(serverTime)}</span>
              <span className="text">{notification}</span>
              <span className="text">  交易延迟: {delay} ms<div className="breathing-dot1"></div></span>
            </div>
          </div>
        </div>
        <a
          href={process.env.REACT_APP_BINANCE_LOGIN_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="follow-button"
        >
          跟单
        </a>
      </div>
      <div className="stats">
        <div className="stat-item">
          <div className="stat-label">项目表现</div>
          <div className="time-range-buttons">
            <button
              onClick={() => setTimeRange('7D')}
              style={{
                backgroundColor: timeRange === '7D' ? '#312231' : '#fff',
                color: timeRange === '7D' ? '#fff' : '#000',
                border: '1px solid #ddd',
                padding: '1px 14px',
                fontSize: '14px',
                borderRadius: '100px',
                cursor: 'pointer',
              }}
            >
              7天
            </button>
            <button
              onClick={() => setTimeRange('30D')}
              style={{
                backgroundColor: timeRange === '30D' ? '#312231' : '#fff',
                color: timeRange === '30D' ? '#fff' : '#000',
                border: '1px solid #ddd',
                padding: '1px 14px',
                fontSize: '14px',
                borderRadius: '100px',
                cursor: 'pointer',
              }}
            >
              30天
            </button>
            <button
              onClick={() => setTimeRange('90D')}
              style={{
                backgroundColor: timeRange === '90D' ? '#312231' : '#fff',
                color: timeRange === '90D' ? '#fff' : '#000',
                border: '1px solid #ddd',
                padding: '1px 14px',
                fontSize: '14px',
                borderRadius: '100px',
                cursor: 'pointer',
              }}
            >
              90天
            </button>
          </div>
          <div className="stat-value">
            <div className="stat-item-data">
              <div className="stat-label-data">收益率</div>
              <div className="stat-value" style={{
                color: Number(performance?.roi ?? 0) > 0 ? '#2ebd85' : (Number(performance?.roi ?? 0) < 0 ? 'red' : 'inherit')
              }}>
                {performance?.roi != null
                  ? `${Number(performance?.roi) > 0 ? '+' : ''}${Number(performance?.roi).toFixed(2)}%`
                  : '0.00%'}
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">盈亏</div>
              <div className="stat-value" style={{
                color: Number(performance?.pnl ?? 0) > 0 ? '#2ebd85' : (Number(performance?.pnl ?? 0) < 0 ? 'red' : 'inherit')
              }}>
                {performance?.pnl != null
                  ? `${Number(performance?.pnl) > 0 ? '+$' : ''}${Number(performance?.pnl).toFixed(2)}`
                  : '0.00'}
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">夏普比率</div>
              <div className="stat-value">
                {performance?.sharpRatio != null ? Number(performance?.sharpRatio).toFixed(2) : '0.00'}
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">最大回撤</div>
              <div className="stat-value">
                {performance?.mdd != null ? Number(performance?.mdd).toFixed(2) : '0.00'}%
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">胜率</div>
              <div className="stat-value">
                {performance?.winRate != null ? Number(performance?.winRate).toFixed(2) : '0.00'}%
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">盈利天数</div>
              <div className="stat-value">{performance?.winDays} 天</div>
            </div>
            <hr style={{ border: '1px dashed #ddd', margin: '10px 0' }} />
            <div className="stat-item-data">
              <div className="stat-label-data">今日利润</div>
              <div className="stat-value">
                <span
                  style={{
                    color: totalCombinedToday !== null && totalCombinedToday >= 0 ? '#2ebd85' : '#ffffff'
                  }}
                >
                  ${totalCombinedToday !== null ? totalCombinedToday.toFixed(2) : ''}
                </span>
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">昨日利润</div>
              <div className="stat-value">
                <span
                  style={{
                    color: totalCombinedYesterday !== null && totalCombinedYesterday >= 0 ? '#2ebd85' : '#ffffff'
                  }}
                >
                  ${totalCombinedYesterday !== null ? totalCombinedYesterday.toFixed(2) : ''}
                </span>
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">本周利润</div>
              <div className="stat-value">
                <span
                  style={{
                    color: totalCombinedWeek !== null && totalCombinedWeek >= 0 ? '#2ebd85' : '#ffffff'
                  }}
                >
                  ${totalCombinedWeek !== null ? totalCombinedWeek.toFixed(2) : ''}
                </span>
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">上月利润</div>
              <div className="stat-value">
                <span
                  style={{
                    color: totalProfitLastMonth !== null && totalProfitLastMonth >= 0 ? '#2ebd85' : '#ffffff'
                  }}
                >
                  ${totalProfitLastMonth !== null ? totalProfitLastMonth.toFixed(2) : ''}
                </span>
              </div>
            </div>
            <hr style={{ border: '1px dashed #ddd', margin: '10px 0' }} />
            <div className="stat-item-data">
              <div className="stat-label-data">今日增长率</div>
              <div className="stat-value">
                <span
                  style={{
                    color: totalCombinedPercToday !== null && totalCombinedPercToday >= 0 ? '#2ebd85' : '#ffffff'
                  }}
                >
                  {totalCombinedPercToday !== null ? totalCombinedPercToday.toFixed(2) : ''}%
                </span>
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">昨日增长率</div>
              <div className="stat-value">
                <span
                  style={{
                    color: totalCombinedPercYesterday !== null && totalCombinedPercYesterday >= 0 ? '#2ebd85' : '#ffffff'
                  }}
                >
                  {totalCombinedPercYesterday !== null ? totalCombinedPercYesterday.toFixed(2) : ''}%
                </span>
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">本周增长率</div>
              <div className="stat-value">
                <span
                  style={{
                    color: totalCombinedPercWeek !== null && totalCombinedPercWeek >= 0 ? '#2ebd85' : '#ffffff'
                  }}
                >
                  {totalCombinedPercWeek !== null ? totalCombinedPercWeek.toFixed(2) : ''}%
                </span>
              </div>
            </div>
            <div className="stat-item-data">
              <div className="stat-label-data">上月增长率</div>
              <div className="stat-value">
                <span
                  style={{
                    color: totalProfitPercLastMonth !== null && totalProfitPercLastMonth >= 0 ? '#2ebd85' : '#ffffff'
                  }}
                >
                  {totalProfitPercLastMonth !== null ? totalProfitPercLastMonth.toFixed(2) : ''}%
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="stat-item">
          <div className="stat-label">收益统计</div>
          <div>
            <div>
              <label>统计类型:</label>
              <select value={dataType} onChange={(e) => setDataType(e.target.value)}>
                <option value="ROI">收益率</option>
                <option value="PNL">盈亏</option>
              </select>
            </div>
            <div>
              <label>时间范围:</label>
              <select value={timeRange} onChange={(e) => setTimeRange(e.target.value)}>
                <option value="7D">7天</option>
                <option value="30D">30天</option>
                <option value="90D">90天</option>
              </select>
            </div>
            {chartData && <Line data={chartData} options={options} width={900} height={250} />}
          </div>
        </div>
        <div className="stat-item">
          <div className="stat-label">带单员总览</div>
          <div className="stat-item-data3">
            <div className="stat-label-data3">跟单者盈亏</div>
            <div className="stat-value" style={{
              color: Number(data?.copierPnl ?? 0) > 0 ? '#2ebd85' : (Number(data?.copierPnl ?? 0) < 0 ? 'red' : 'inherit')
            }}>
              {data?.copierPnl != null
                ? `${Number(data?.copierPnl) > 0 ? '+$' : ''}${Number(data?.copierPnl).toFixed(2)}`
                : '0.00%'}
            </div>
          </div>
          <div className="stat-item-data3">
            <div className="stat-label-data3">带单余额</div>
            <div className="stat-value">
              ${data?.walletBalanceAmount != null ? Number(data?.walletBalanceAmount).toFixed(2) : '0.00'}
            </div>
          </div>
          <div className="stat-item-data3">
            <div className="stat-label-data3">资产管理规模</div>
            <div className="stat-value">
              ${data?.aumAmount != null ? Number(data?.aumAmount).toFixed(2) : '0.00'}
            </div>
          </div>
          <div className="stat-item-data3">
            <div className="stat-label-data3">分润比例</div>
            <div className="stat-value">
              {data?.profitSharingRate != null ? Number(data?.profitSharingRate).toFixed(2) : '0.00'} %
            </div>
          </div>
          <div className="stat-item-data3">
            <div className="stat-label-data3">最小跟单金额</div>
            <div className="stat-value">
              {data?.fixedAmountMinCopyUsd != null ? Number(data?.fixedAmountMinCopyUsd).toFixed(0) : '0'}/100 USDT
            </div>
          </div>
          <div className="stat-item-data3">
            <div className="stat-label-data3">最新交易</div>
            <div className="stat-value">
              {data?.lastTradeTime != null
                ? new Date(Number(data?.lastTradeTime)).toLocaleString('zh-CN', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false,
                }).replace(/\//g, '-')
                : '无数据'}
            </div>
          </div>
        </div>
        <div className="stat-item">
          <div className="stat-label">资产偏好</div>
          <div className="time-range-selector">
            <label>时间范围:</label>
            <select value={timeRange} onChange={(e) => handleTimeRangeChange(e.target.value)}>
              <option value="7D">7天</option>
              <option value="30D">30天</option>
              <option value="90D">90天</option>
            </select>
          </div>
          <div className="pie-chart-container">
            <div className="pie-chart-left">
              <Pie data={pieChartData} options={pieChartOptions} width={250} height={250} />
            </div>
            <div className="pie-chart-right">
              <ul className="indicator-list">
                {pieChartData.labels?.map((label, index) => {
                  const displayLabel = label === 'OTHER' ? '其他' : label;
                  const logo = assetLogos.find((logo) => logo.asset === label && label !== 'OTHER');
                  return (
                    <li key={index} className="indicator-item">
                      <div className="indicator-item-content">
                        {logo ? (
                          <img src={logo.pic} alt={displayLabel} className="asset-logo" />
                        ) : (
                          <div className="asset-logo-placeholder"></div>
                        )}
                        <span>{displayLabel}: {pieChartData.datasets[0].data[index]}%</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div style={{ color: '#949494', fontSize: '13px', margin: 0 }}>
            *数据每 1-2 小时刷新一次
          </div>
        </div>
      </div>
    </div>
  );
};
export default BinanceCopyTrading;
