/*
 * 作者：宫商角徵羽
 * 微信：NANSA_Bee
 * 创建日期：2024/12/10
 */

import React, { useEffect, useState } from 'react';
import './Sales.css';
type TradeData = {
    time: number;
    symbol: string;
    side: string;
    role: string;
    executed: string;
    fee: string;
    feeAsset: string;
    totalAmount: string;
    baseAsset: string
};
const Sales: React.FC = () => {
    const [tradeData, setTradeData] = useState<TradeData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize] = useState<number>(100);
    const [totalPages, setTotalPages] = useState<number>(100);
    const [portfolioId] = useState<string>('4145031067802711040');
    const [assetLogos, setAssetLogos] = useState<Record<string, string>>({});
    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };
    const fetchTradeData = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/trade-history?pageNumber=${pageNumber}&pageSize=${pageSize}&portfolioId=${portfolioId}`
            );
            const result = await response.json();
            if (result.success) {
                const filteredData = result.data.filter((trade: TradeData) => trade.side !== 'BUY');
                setTradeData(filteredData);
                const maxPages = Math.min(result.totalPages || 100, 100);
                setTotalPages(maxPages);
                filteredData.forEach((trade: any) => {
                    fetchAssetLogo(trade.baseAsset);
                });
            }
        } catch (error) {
            console.error('Error fetching trade data:', error);
        } finally {
            setLoading(false);
        }
    };
    const fetchAssetLogo = async (baseAsset: string) => {
        try {
            const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/asset-logos?asset=${baseAsset}`;
            const response = await fetch(apiUrl);
            const data = await response.json();
            if (data.success && data.pic) {
                setAssetLogos((prevLogos) => ({
                    ...prevLogos,
                    [baseAsset]: data.pic,
                }));
            }
        } catch (error) {
            console.error('Error fetching asset logo:', error);
        }
    };
    useEffect(() => {
        fetchTradeData();
    }, [pageNumber]);
    const handlePreviousPage = () => {
        setPageNumber((prev) => Math.max(prev - 1, 1));
    };
    const handleNextPage = () => {
        setPageNumber((prev) => Math.min(prev + 1, totalPages));
    };
    const handleJumpToPage = (page: number) => {
        if (page >= 1 && page <= totalPages) {
            setPageNumber(page);
        }
    };
    return (
        <div className="stat-items">
            <div className="positions-container">
                <div className="headers-row">
                    <div className="header-cell">日期</div>
                    <div className="header-cell">交易对</div>
                    <div className="header-cell">方向</div>
                    <div className="header-cell">角色</div>
                    <div className="header-cell">成交量</div>
                    <div className="header-cell">手续费</div>
                    <div className="header-cell">总计</div>
                </div>
                <div className="data-scrollable">
                    {loading ? (
                        <div>准备中🚀...</div>
                    ) : (
                        tradeData.map((trade, index) => (
                            <div key={index} className="items-row">
                                <div className="item-cell">{formatDate(trade.time)}</div>
                                <div className="item-cell">
                                    {assetLogos[trade.baseAsset] && (
                                        <img
                                            src={assetLogos[trade.baseAsset]}
                                            alt={trade.baseAsset}
                                            style={{ width: '20px', height: '20px', marginRight: '5px' }}
                                        />
                                    )}
                                    {trade.symbol}
                                </div>
                                <div className="item-cell">平仓</div>
                                <div className="item-cell">{trade.role}</div>
                                <div className="item-cell">{trade.executed}</div>
                                <div className="item-cell">{trade.fee} {trade.feeAsset}</div>
                                <div className="item-cell">${parseFloat(trade.totalAmount).toFixed(2)}</div>
                            </div>
                        ))
                    )}
                </div>
                <div className="pagination">
                    <button onClick={handlePreviousPage} disabled={pageNumber === 1}>
                        上一页
                    </button>
                    <span>当前页: {pageNumber} / {totalPages}</span>
                    <button onClick={handleNextPage} disabled={pageNumber === totalPages}>
                        下一页
                    </button>
                </div>
                <div>
                    <label>矢轮: </label>
                    <input
                        type="number"
                        min="1"
                        max={totalPages}
                        value={pageNumber}
                        onChange={(e) => handleJumpToPage(Number(e.target.value))}
                    />
                </div>
            </div>
        </div>
    );
};
export default Sales;

